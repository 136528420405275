(function (window, document) {
    'use strict';

    var widget = document.getElementById('js-resizable');
    var postMessageToParentDebounced = debounce(postMessageToParent, 200);

    postMessageToParentDebounced({
        status: 'rendered',
        width: widget.scrollWidth,
        height: widget.offsetHeight
    });

    window.addEventListener('resize', function() {
        postMessageToParentDebounced({
            status: 'rendered',
            width: widget.scrollWidth,
            height: widget.offsetHeight
        });
    })

    /**
     * https://davidwalsh.name/javascript-debounce-function
     * Returns a function, that, as long as it continues to be invoked, will not
     * be triggered. The function will be called after it stops being called for
     * N milliseconds. If `immediate` is passed, trigger the function on the
     * leading edge, instead of the trailing.
     * @param {*} func
     * @param {*} wait
     * @param {*} immediate
     */
    function debounce(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    /**
     * Stringify object
     * @private
     * @param {Object} message
     * @returns {string}
     */
    function stringify (message) {
        var value = '' + message;

        if (typeof message === 'object') {
            value = '';
            for (var i in message) {
                if (!message.hasOwnProperty(i)) {
                    continue;
                }
                if (message[i] === null) {
                    continue;
                }
                value += i + '=' + message[i] + '&';
            }
        }
        return value;
    }

    /**
     * Post message to parent window
     * @param message
     */
    function postMessageToParent (message) {
        if (!window.parent || window.parent === window) {
            return;
        }
        if (!window.parent.postMessage) {
            return;
        }
        window.parent.postMessage(stringify(message), '*');
    }

})(window, document);
